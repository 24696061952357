import React from "react"
import { Link } from "~components"

import Card from "./style"
export default function RelatedCard({ to, title, text, image }) {
  return (
    <Card as={Link} link={to ? to : "/"} className={`w-100`} style={{ borderRadius: "12px", overflow: "hidden" }}>
      <Card.Image backgroundImage={image.childImageSharp.gatsbyImageData.images.fallback.src} />
      <Card.Content>
        <Card.InnerBlock backgroundColor="rgba(0,0,0,0.8)">
          <Card.Text fontColor="#fff">{text}</Card.Text>
          <Card.Title as="h3" fontColor="#fff">
            {title}
          </Card.Title>
        </Card.InnerBlock>
      </Card.Content>
    </Card>
  )
}
